/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~swiper/swiper-bundle.css";

/* .Show-backdrop{
    display: block;
} */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.charCount {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16.5ch;
}

/* .searchStyle{
    position: fixed;
    right: 64px;
    top: 119px;
} */

.svgSize {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.plr-30{
	padding-right: 30px;
}
/* ::-webkit-scrollbar{
    width: 10px;

}

::-webkit-scrollbar-track{
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb{
    background: #007bff;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
    background: #007bff;
} */

/* Changes for Bug & Layout Related Problem Solve */

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/*
For ngx-pagination */

.alignStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.alignStyles /deep/ .ngx-pagination a:hover,
.alignStyles /deep/ .ngx-pagination button:hover {
  text-decoration: none !important;
}

.alignStyles /deep/ .ngx-pagination .current {
  background: #3574c0;
}

/* Layout related bug fixing 28-6 */

/*
.ev-navbar{
    height: 100px !important;
}
.aside-wrapper{
    height: calc(100vh - 100px) !important;
    width: 25% !important;
    max-width: 25% !important;
    overflow: hidden;
}
#myTab{
    position: sticky;
    top: 0;
    width: 100%;
    background-color: white;
    overflow: hidden !important;
    z-index: 2;
    padding-top: 24px;
}
.tab-content{
    height: calc(100% - 65px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}
.content-wrapper{
    height: calc(100vh - 100px) !important;
    width: calc(75% - 30px) !important;
    max-width: calc(75% - 30px) !important;
    padding: 0 15px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}
.aside-menu{
    margin-top: 0 !important;
}
@media (min-width: 0px) and (max-width: 575.98px) {
    .aside-wrapper{
        display: none;
    }
    .content-wrapper{
        width: 100% !important;
        max-width: 100% !important;
        overflow-y: auto !important;
    }
    .navbar{
        z-index: 3 !important;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .aside-wrapper{
        display: none;
    }
    .content-wrapper{
        width: 100% !important;
        max-width: 100% !important;
        overflow-y: auto !important;
    }
    .navbar{
        z-index: 3 !important;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .content-wrapper{
        overflow-y: auto;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .content-wrapper{
        overflow-y: auto;
    }
}
@media (min-width: 1200px) {
    .tab-content{
        height: 100% !important;
    }
    .content-wrapper{
        overflow-y: auto;
    }
} */
/*
.backdrop{
    display: none;
} */

.noInternet {
  margin-top: 100px !important;
}
